import invariant from 'tiny-invariant'
import { ChainId } from '../constants'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId
  public readonly address: string

  public constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WETH = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xf54093264A21D62d299494A22B2968fb25e17B8e',
    18,
    'WMOZ',
    'Wrapped Ether'
  ),
  // [ChainId.ROPSTEN]: new Token(
  //   ChainId.ROPSTEN,
  //   '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  //   18,
  //   'WETH',
  //   'Wrapped Ether'
  // ),
  // [ChainId.RINKEBY]: new Token(
  //   ChainId.RINKEBY,
  //   '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  //   18,
  //   'WETH',
  //   'Wrapped Ether'
  // ),
  // [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', 18, 'WETH', 'Wrapped Ether'),
  // [ChainId.KOVAN]: new Token(ChainId.KOVAN, '0xd0A1E359811322d97991E03f863a0C30C2cF029C', 18, 'WETH', 'Wrapped Ether'),
  // [ChainId.RolluxTestnet]: new Token(
  //   ChainId.RolluxTestnet,
  //   '0x5EbA32b55015d13B515a4c7c227709b6820F32AC',
  //   18,
  //   'TSYS',
  //   'Wrapped Ether'
  // ),
  [ChainId.OpsideTestnet]: new Token(
    ChainId.OpsideTestnet,
    '0xB5350F5F6514103Bc0A6CFECE2d644042437C769',
    18,
    'WMOZ',
    'Wrapped Ether'
  ),
  [ChainId.ODXTestnet]: new Token(
    ChainId.ODXTestnet,
    '0xc7a1bAe0Db6203F3Ee3C721909B3b959a1b437Ca',
    18,
    'WMOZ',
    'Wrapped Ether'
  ),
  [ChainId.ZKFairMainnet]: new Token(//TODO
    ChainId.ZKFairMainnet,
    '0xD33Db7EC50A98164cC865dfaa64666906d79319C',
    18,
    'WUSDC',
    'Wrapped USDC'
  )
  // [ChainId.OpsidePubliczkEVMV2]: new Token(
  //   ChainId.OpsidePubliczkEVMV2,
  //   '0x5533E197DBD651D01f656D02DeFaA59C124cFEc0',
  //   18,
  //   'MOZ',
  //   'Wrapped Ether'
  // )
}
